import React, { useEffect, useState } from 'react';
import './App.css';
import Image from 'react-bootstrap/Image';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useAuthentication, Authentication } from "./auth";
import { useAuthContext, UserAuthenticated } from "./context";
import { LogOut } from './auth/LogOut';
import { Signpost } from './signpost';
import { useSignpostData } from './signpost/useData';

function App() {
  const { retrieveSignpostData, saveSignpostData, dataVersion } = useSignpostData();
  const { authStatus } = useAuthContext();
  const { refreshLogin } = useAuthentication();
  const [signpostData, updateSignpostData] = useState([])

  useEffect(() => {
    if (authStatus === UserAuthenticated.UNKNOWN) {
        refreshLogin();
    }
}, []);

  useEffect(() => {
    retrieveSignpostData().then(
      (data: any) => updateSignpostData(data)
    );
  }, [dataVersion, retrieveSignpostData]);

  const [showLogin, updateShowLogin] = useState(false);

  return (
    <Container>
      <Row>
        <Col>
        <Row>
          <Col><Image src="practice_logo.png" rounded /></Col>
        </Row>
        </Col>
        <Col></Col>
        <Col>
        { authStatus === UserAuthenticated.UNKNOWN ?
         <Spinner variant="info" size="sm">
          <span className="visually-hidden">Authenticating...</span>
          </Spinner> :
          authStatus === UserAuthenticated.YES ?
          <LogOut /> :
          <Button data-testid="forgot" variant="link" className="mb-1" onClick={() => updateShowLogin(true)}>
                Admin Login
            </Button> }
        </Col>
      </Row>
      {!showLogin || authStatus === UserAuthenticated.YES ? (
        <Row>
        <Col>
          <Signpost data={signpostData} saveSignpostData={saveSignpostData} isAdmin={authStatus===UserAuthenticated.YES} />
        </Col>
      </Row>
      ) : (
      <Row>
          <Col className="col-lg-6 mt-5 offset-lg-3">
              <Authentication />
              <Button variant="link" onClick={()=>updateShowLogin(false)}>&lt;- Go Back</Button>
          </Col>
      </Row>

  )}
  <Row>
    <Col></Col>
    <Col></Col>
    <Col>Version: 1.0.0.17</Col>
  </Row>
  </Container>
  )
}
export default App;