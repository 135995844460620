import axios from "axios";
import { useConfigContext } from "../context/config-context";
import { useCallback, useState } from "react";
import { useAuthContext } from "../context";

const useSignpostData = () => {
    const { apiBaseUrl } = useConfigContext();
    const {
        tokenStore: { idToken },
    } = useAuthContext();
    const [dataVersion, updateDataVersion] = useState(0);

    axios.defaults.headers.common.Authorization = `${idToken}`;

    const retrieveSignpostData = useCallback(async () => {
        try {
            const { data } = await axios.get(`${apiBaseUrl}/conditions`);
            return data || [];
        } catch (error) {
            console.error(error);
            return [{}];
        }
    }, [apiBaseUrl]);

    const saveSignpostData = async (conditions: any) => {
        try {
            await axios.put(`${apiBaseUrl}/conditions`, {data: conditions});
            updateDataVersion(v => v += 1);
        } catch (error) {
            console.error(error);
        }
    }

    return { retrieveSignpostData, saveSignpostData, dataVersion };
};

export { useSignpostData };
